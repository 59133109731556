import React, {useState, useEffect, useRef, useContext} from 'react';
import {GeneralContext, useDigitalWallView} from "../../contexts/GeneralContext";
import LoadingCard from "./LoadingCard";
import {Utility} from "../../shared-components/Utility";
import {language} from "../../shared-components/content/language";

const EventPostList = ({posts}) => {
    const {
        appLang,
        eventId,
        pollId,
        batchEndDetails,
        postLoading,
        firstPostId,
        setBlockFetchPosts,
        getNextPostBatch,
        getEventPosts,
    } = useContext(GeneralContext);
    const content = language[appLang];
    const [fetchMoreInitiated, setFetchMoreInitiated] = useState(false);
    const [lastLoadedBatchEndDetails, setLastLoadedBatchEndDetails] = useState(null);
    const containerRef = useRef(null);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [eventId, batchEndDetails, postLoading, fetchMoreInitiated, lastLoadedBatchEndDetails]);

    useEffect(() => {
        if (fetchMoreInitiated) {
            getNextPostBatch()
                .then((updatedBatchEndDetails) => {
                    setFetchMoreInitiated(false);
                    setLastLoadedBatchEndDetails(updatedBatchEndDetails);
                })
                .catch((e) => {
                    console.error(e);
                    setFetchMoreInitiated(false);
                });
        }
    }, [fetchMoreInitiated]);

    const handleScroll = () => {
        if (window.scrollY === 0 && !postLoading) {
            console.log("refresh scroll")
            getEventPosts(eventId, pollId, {postDate: new Date(), isRefresh: true})
                .then(() => {
                    setLastLoadedBatchEndDetails(null);
                });
        }

        if (window.scrollY > window.innerHeight) {
            setBlockFetchPosts(true);
        } else setBlockFetchPosts(false);

        const container = containerRef.current
        if (
            !!container
            && !fetchMoreInitiated
            && batchEndDetails.id !== firstPostId
            && window.innerHeight + window.scrollY >= container.offsetHeight - 200
        ) {
            setFetchMoreInitiated(true);
        }
    };

    const renderDigitalWallView = () => {
        const widthToHeightRatio = window.innerWidth / window.innerHeight;
        const columnNumber = Math.floor(widthToHeightRatio * (9 / 4));
        const postsInSubArrays = Utility.splitArrayIntoMultiple(posts, columnNumber);
        return (
            <div className="digital-wall special-scroll" ref={containerRef}>
                {postsInSubArrays.map((postColumn, index) =>
                    <div
                        key={"digital-wall-column-" + index}
                        className="digital-wall-column"
                        style={{width: `calc(calc(100% - 16px) / ${columnNumber})`}}
                    >
                        {postColumn}
                        {fetchMoreInitiated
                            ? <LoadingCard message={`${Utility.capitalize(content.LOADING)}...`}/>
                            : null
                        }
                    </div>
                )}
            </div>
        )
    }

    const renderSingleScrollView = () => {
        return (
            <div ref={containerRef}>
                {posts}
                {fetchMoreInitiated
                    ? <LoadingCard message={`${Utility.capitalize(content.LOADING)}...`}/>
                    : null
                }
            </div>
        )
    }

    if (useDigitalWallView) {
        return renderDigitalWallView();
    } else return renderSingleScrollView();
};

export default EventPostList;