import './appStyle.scss';
import {Box} from "@mui/material";
import {Suspense, useContext, useEffect} from "react";
import {GeneralContext} from "./contexts/GeneralContext";
import AppRouter from "./components/AppRouter";
import {ConstantLoaderModal} from "./components/modals/LoaderModal";
import ModalContainer from "./components/modals/ModalContainer";

function App() {
    const {
        eventIsActive,
        setContentLoading,
        clearTempMedia,
        getEventData,
    } = useContext(GeneralContext);

    const urlSearchParams = new URLSearchParams(window.location.search);
    const eventId = urlSearchParams.get('id');

    useEffect(() => {
        getEventData(eventId).then(() => {
            setContentLoading(false);
        });
        return () => clearTempMedia();
    }, []);

    return (
        <div className="app small-text-regular">
            <Box className="app-container">
                <AppRouter eventIsActive={eventIsActive}/>
            </Box>
            <Suspense fallback={ConstantLoaderModal}>
                <ModalContainer/>
            </Suspense>
        </div>
    );
}

export default App;
