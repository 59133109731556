import React, {useContext} from 'react';
import {routes, GeneralContext} from "../contexts/GeneralContext";
import {language} from "../shared-components/content/language";
import {useNavigate} from "react-router-dom";
import {Utility} from "../shared-components/Utility";

const AppHeader = ({withSearch, isViewPage}) => {
    const {
        appLang,
        eventColor,
        eventId,
    } = useContext(GeneralContext);
    const content = language[appLang];
    const navigate = useNavigate();

    const shareIsCurrent = window.location.pathname === routes.UPLOAD + "/";

    return (
        <div className={`app-header${withSearch ? " with-search" : ""}`}>
            <div className="top-bar-container">
                <div
                    className={`top-bar-tab small-text-regular${shareIsCurrent ? " current" : ""}`}
                    style={shareIsCurrent
                        ? {backgroundColor: `${eventColor}33`, borderBottom: `solid 3px ${eventColor}`}
                        : {backgroundColor: `${eventColor}1A`, borderBottom: `solid 3px ${isViewPage ? "#F5F3F7" : "#FFFFFF"}`}
                    }
                    onClick={() => navigate(routes.UPLOAD + "/?id=" + eventId)}
                >
                    {Utility.capitalize(content.SHARE)}
                </div>
                <div
                    className={`top-bar-tab small-text-regular${!shareIsCurrent ? " current" : ""}`}
                    style={!shareIsCurrent
                        ? {backgroundColor: `${eventColor}33`, borderBottom: `solid 3px ${eventColor}`}
                        : {backgroundColor: `${eventColor}1A`, borderBottom: `solid 3px ${isViewPage ? "#F5F3F7" : "#FFFFFF"}`}
                    }
                    onClick={() => navigate(routes.VIEW + "/?id=" + eventId)}
                >
                    {Utility.capitalize(content.VIEW)}
                </div>
            </div>
            {withSearch
                ? <div>"SEARCH"</div>
                : null
            }
        </div>

    );
};

export default AppHeader;