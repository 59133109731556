import React from "react";
import "../componentStyle.scss";
import instagramLogo from "../../shared-components/assets/instagram-logo.png";
import facebookLogo from "../../shared-components/assets/facebook-logo.png";
import tiktokLogo from "../../shared-components/assets/tiktok-logo.png";
import linkedInLogo from "../../shared-components/assets/linkedin-logo.png";
import {Utility} from "../../shared-components/Utility";

const SocialMediaLinksPost = ({socialMedia, label}) => {
    const renderLinks = () => {
        return (
            <div className="social-media-links">
                {!!socialMedia.instagram && <img src={instagramLogo} alt="Instagram" onClick={() => Utility.openInNewTab(socialMedia.instagram)}/>}
                {!!socialMedia.facebook && <img src={facebookLogo} alt="Facebook" onClick={() => Utility.openInNewTab(socialMedia.facebook)}/>}
                {!!socialMedia.tiktok && <img src={tiktokLogo} alt="TikTok" onClick={() => Utility.openInNewTab(socialMedia.tiktok)}/>}
                {!!socialMedia.linkedIn && <img src={linkedInLogo} alt="LinkedIn" onClick={() => Utility.openInNewTab(socialMedia.linkedIn)}/>}
            </div>
        )
    }

    return (
        <div className="event-post additional-text">
            <div className="post-header">
                <div>{label}</div>
            </div>
            {renderLinks()}
        </div>
    );
};

export default SocialMediaLinksPost;