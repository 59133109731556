import React from "react";
import "../componentStyle.scss";
import {HashLoader} from "react-spinners";

const LoadingCard = ({message}) => {
    return (
        <div className="event-post additional-text loading-card">
            <HashLoader
                size={20}

            />
            <span>{message}</span>
        </div>
    );
};

export default LoadingCard;