import React, {useContext} from "react";
import {BrowserRouter as Router, Route, Routes, Navigate} from "react-router-dom";
import {routes, GeneralContext, useDigitalWallView} from "../contexts/GeneralContext";
import NotFound from "./NotFound";
import LandingPage from "./LandingPage";
import SharePage from "./SharePage";
import ViewPage from "./ViewPageComponents/ViewPage";
import TermsAndConditions from "./TermsAndConditions";
import StyledSnackbar from "../shared-components/generic/StyledSnackbar";

const AppRouter = ({eventIsActive}) => {
    const {
        errorMsg,
        setErrorMsg,
        eventColor,
    } = useContext(GeneralContext);

    const handleCloseErrorMsg = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setErrorMsg("");
    }

    return (
        <div
            className={`main middle-text${useDigitalWallView ? " digital-wall-view" : ""}`}
            style={useDigitalWallView ? {"--scrollbar-color": eventColor} : null}
        >
            <Router>
                <Routes>
                    <Route exact path={routes.ROOT} element={eventIsActive ? <LandingPage/> : <NotFound/>}/>
                    <Route exact path={routes.UPLOAD} element={eventIsActive ? <SharePage/> : <NotFound/>}/>
                    <Route exact path={routes.VIEW} element={eventIsActive ? <ViewPage/> : <NotFound/>}/>
                    <Route exact path={routes.TERMS_AND_CONDITIONS} element={<TermsAndConditions/>}/>
                    <Route path={routes.WILDCARD} element={<Navigate to={routes.NOT_FOUND}/>}/>
                    <Route path={routes.NOT_FOUND} element={<NotFound/>}/>
                </Routes>
            </Router>
            <StyledSnackbar
                open={!!errorMsg}
                onClose={handleCloseErrorMsg}
                autoHideDuration={4500}
                type="error"
                message={errorMsg}
            />
        </div>
    );
};

export default AppRouter;