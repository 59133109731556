import React, {useContext} from "react";
import "../componentStyle.scss";
import {Heart} from "@phosphor-icons/react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Utility} from "../../shared-components/Utility";
import {GeneralContext, useDigitalWallView} from "../../contexts/GeneralContext";
import {IconButton} from "@mui/material";

const borderColor = "#ABA7AF";

const EventPost = ({eventPost, eventColor}) => {
    const {
        addLike
    } = useContext(GeneralContext);

    const sliderSettings = {
        dots: false,
        arrows: false,
        infinite: eventPost.mediaUrls.length > 1,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        slidesToShow: 1,
        slidesToScroll: 1,
    }

    const posterName = eventPost.name || "Anonymous attendee";
    const hasLikes = !!eventPost.likes?.length;

    const renderMedia = () => {
        return <Slider {...sliderSettings}>
            {eventPost.mediaUrls.map((urlObj, index) => {
                const mediaUrl = useDigitalWallView ? urlObj?.url : urlObj?.thumbnailUrl || urlObj?.url;
                return (
                    <div className="media-container" key={`post-media-${index}`}>
                        {Utility.renderMedia(mediaUrl || "", `Media number ${index + 1} for ${posterName}'s post.`, null,"rendered-media")}
                    </div>
                )
            })}
        </Slider>
    }

    return (
        <div className="event-post additional-text">
            <div className="post-header">
                <div>{posterName}</div>
                <div className={`like-icon${hasLikes ? " has-likes" : ""}`}>
                    <span>{hasLikes ? eventPost.likes.length : ""}</span>
                    <IconButton onClick={() => addLike(eventPost.id)}>
                        <Heart size={24} color={hasLikes ? eventColor : borderColor} weight={hasLikes ? "fill" : "bold"}/>
                    </IconButton>
                </div>
            </div>
            {renderMedia()}
            {eventPost.blurb
                ? <div className="post-blurb">
                    {eventPost.blurb}
                </div>
                : null
            }
        </div>
    );
};

export default EventPost;