import React, {useContext} from "react";
import ClipLoader from "react-spinners/ClipLoader";
import {Modal} from "@mui/material";
import enums from "../../enums.json";
import {GeneralContext} from "../../contexts/GeneralContext";

const {colors} = enums;

const renderLoader = (loaderOpen, eventColor) => {
    return (
        <Modal open={loaderOpen}>
            <div className="spinner">
                <ClipLoader
                    loading={loaderOpen}
                    size={100}
                    color={eventColor || colors.BASE_RED}
                />
            </div>
        </Modal>
    )
}

export const ConstantLoaderModal = () => {
    return renderLoader(true);
}

const LoaderModal = () => {
    const {contentLoading, eventColor} = useContext(GeneralContext);
    return renderLoader(contentLoading, eventColor);
}

export default LoaderModal;