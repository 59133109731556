import React, {createContext, useEffect, useState} from "react";
import enums from '../enums.json';
import {language} from "../shared-components/content/language";
import {Utility} from "../shared-components/Utility";
import {isMobile} from "react-device-detect";

const pollInterval = 1000 * 15;     //15 seconds
const activityDropOff = 1000 * 120;  //2 minutes

const {languages, colors, batchEnum} = enums;

export const routes = {
    ROOT: "/",
    UPLOAD: "/upload",
    VIEW: "/view",
    TERMS_AND_CONDITIONS: "/terms-and-conditions",
    WILDCARD: "/*",
    NOT_FOUND: "/404"
}

export const useDigitalWallView = !isMobile;
const batchSize = useDigitalWallView ? batchEnum?.WALL_SIZE || 60 : batchEnum?.FEED_SIZE || 20;
export const postIndexes = [Math.floor(Math.random() * batchSize), Math.floor(Math.random() * batchSize)];

export const GeneralContext = createContext(null);

export const GeneralProvider = (props) => {
    const [contentLoading, setContentLoading] = useState(true);
    const [postLoading, setPostLoading] = useState(false);
    const [uploadMediaModalOpen, setUploadMediaModalOpen] = useState(false);
    const [appLang, setAppLang] = useState(languages.ENGLISH);
    const content = language[appLang];
    // const [langDir, setLangDir] = useState("ltr");   //save to use if needed for rtl languages

    const [currentUploads, setCurrentUploads] = useState([]);
    const [objectUrls, setObjectUrls] = useState([]);
    const [hasAcceptedTerms, setHasAcceptedTerms] = useState(true);
    const [userId, setUserId] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userName, setUserName] = useState("");
    const [userComments, setUserComments] = useState("");
    const [eventIsActive, setEventIsActive] = useState(true);
    const [eventName, setEventName] = useState("");
    const [eventColor, setEventColor] = useState(colors.BASE_RED);
    const [eventId, setEventId] = useState("");
    const [firstPostId, setFirstPostId] = useState("");
    const [eventDates, setEventDates] = useState({
        startDts: new Date(),
        endDts: new Date(Date.now() + 1000 * 60 * 60 * 24),
        filterActive: false
    });
    const [shareEmailActive, setShareEmailActive] = useState(false);
    const [socialMedia, setSocialMedia] = useState({});
    const [coverImages, setCoverImages] = useState([]);
    const [eventPostMap, setEventPostMap] = useState(new Map());
    const [batchEndDetails, setBatchEndDetails] = useState({postDate: new Date()});
    const [blockFetchPosts, setBlockFetchPosts] = useState(false);
    const [pollTimer, setPollTimer] = useState(setTimeout(() => {
    }, 100));
    const [pollState, setPollState] = useState(Date.now());
    const [pollId, setPollId] = useState("");
    const [postIndexes, setPostIndexes] = useState([Math.ceil(Math.random() * batchSize), Math.ceil(Math.random() * batchSize)]);
    const [errorMsg, setErrorMsg] = useState("");
    const [lastActiveMillis, setLastActiveMillis] = useState(Date.now());
    const [lastUpdateDts, setLastUpdateDts] = useState(new Date());

    useEffect(() => {
        const existingId = localStorage.getItem("scale-social-events-id");
        setUserId(existingId || Utility.makeId(20));

        const newPollTimer = setInterval(() => {
            setPollState(Date.now());
        }, pollInterval);
        setPollTimer(newPollTimer);

        return () => clearTimeout(pollTimer);
    }, []);

    const handleUserActivity = () => {
        setLastActiveMillis(Date.now());
    }

    useEffect(() => {
        const events = [
            'mousemove',
            'mousedown',
            'keydown',
            'scroll',
            'touchstart',
            'touchmove',
            'resize'
        ];

        events.forEach((event) => window.addEventListener(event, handleUserActivity));

        return () => {
            events.forEach((event) => window.removeEventListener(event, handleUserActivity));
        };
    }, [lastActiveMillis]);

    useEffect(() => {
        if (!!eventId && !!pollId) {
            if (blockFetchPosts) {
                getEventPostUpdates().then();
            } else {
                getEventPosts(eventId, pollId, null).then();
            }
        }
    }, [pollState]);

    const switchLanguage = language => {
        setAppLang(language);
        // setLangDir(language === languages.HEBREW || language === languages.ARABIC ? "rtl" : "ltr")   //save to use if needed for rtl languages
    };

    const getUserIsActive = () => lastActiveMillis > Date.now() - activityDropOff;

    const uploadMedia = async (files) => {
        const newUploads = [];
        const updatedObjectUrls = [...objectUrls];
        const convertToBase64Promises = [];

        files.forEach((file) => {
            if (file.size > 25000000) {
                setErrorMsg(Utility.capitalize(content.FILE_TOO_LARGE))
                return;
            }

            const lastModifiedDate = new Date(file.lastModified || file.lastModifiedDate || 0);
            if (
                eventDates.filterActive
                && (
                    new Date(eventDates.startDts) > lastModifiedDate
                    || new Date(eventDates.endDts) < lastModifiedDate
                )
            ) {
                setErrorMsg(Utility.capitalize(content.PLEASE_USE_CURRENT_MEDIA))
                return;
            }

            const metaData = {
                type: file.type,
                size: file.size,
                fileExt: file.name.split(".").pop(),
            }
            const tempUrl = URL.createObjectURL(file);

            const tempUpload = {
                metaData,
                tempUrl
            }

            convertToBase64Promises.push(Utility.fileToBase64(file));
            newUploads.push(tempUpload);
            updatedObjectUrls.push(tempUrl);
        });

        const arrayBufferResults = await Promise.all(convertToBase64Promises);

        setCurrentUploads([...currentUploads, ...newUploads.map((updatedUpload, index) => {
            return {
                ...updatedUpload,
                fileDataBase64: arrayBufferResults[index]
            }
        })]);
        setObjectUrls(updatedObjectUrls);
    }

    const uploadBlob = async (blob, fileExt) => {
        if (blob.size > 25000000) {
            setErrorMsg(Utility.capitalize(content.FILE_TOO_LARGE))
            return;
        }

        const updatedObjectUrls = [...objectUrls];

        const metaData = {
            type: blob.type,
            size: blob.size,
            fileExt,
        }
        const tempUrl = URL.createObjectURL(blob);

        const arrayBufferResult = await Utility.fileToBase64(blob);

        const tempUpload = {
            metaData,
            tempUrl,
            fileDataBase64: arrayBufferResult,
        }
        updatedObjectUrls.push(tempUrl);

        setCurrentUploads([...currentUploads, tempUpload]);
        setObjectUrls(updatedObjectUrls);
    }

    const clearTempMedia = () => {
        currentUploads.forEach((upload) => {
            if (!!upload.tempUrl) {
                URL.revokeObjectURL(upload.tempUrl);
            }
        });
        setCurrentUploads([]);
    }

    const submitContent = async () => {
        const updatedEventPostMap = new Map(eventPostMap);
        updatedEventPostMap.set("loading", "loading");
        setEventPostMap(updatedEventPostMap);
        setPostLoading(true);
        Utility.sleep(100).then(() => window.scrollTo(0, 1));

        const contentData = currentUploads.map((upload) => {
            const modifiedUpload = {...upload};
            delete modifiedUpload.tempUrl;

            return modifiedUpload;
        });

        const postId = Utility.makeId(20);

        try {
            const response = await Utility.httpCall("submitEventContent", {
                eventId,
                eventName,
                contentData,
                userId,
                userName,
                userComments,
                postId,
                batchSize
            });


            if (!!response?.data) {
                clearTempMedia();
                if (!!response.data.eventPosts) {
                    processEventPostResponse(response, pollId, null);
                } else {
                    getEventPosts(eventId, pollId, null).catch((e) => {
                        console.error(e);
                        const updatedEventPostMap = new Map(eventPostMap);
                        updatedEventPostMap.delete("loading");
                        setEventPostMap(updatedEventPostMap);
                    });
                }
            } else {
                const updatedEventPostMap = new Map(eventPostMap);
                updatedEventPostMap.delete("loading");
                setEventPostMap(updatedEventPostMap);
                setErrorMsg(Utility.capitalize(content.THERE_WAS_A_PROBLEM_UPLOADING_YOUR_CONTENT_SENTENCE));
            }

            setPostLoading(false);
        } catch (e) {
            console.error(e);
            const updatedEventPostMap = new Map(eventPostMap);
            updatedEventPostMap.delete("loading");
            setEventPostMap(updatedEventPostMap);
            setErrorMsg(Utility.capitalize(content.THERE_WAS_A_PROBLEM_UPLOADING_YOUR_CONTENT_SENTENCE));
            setPostLoading(false);
        }
    }

    const getEventData = async (id) => {
        if (!id) {
            setEventIsActive(false);
            return
        }

        try {
            const response = await Utility.httpCall("getEventProps", {
                eventId: id,
                props: [
                    "eventIsActive",
                    "eventName",
                    "eventColor",
                    "eventCoverImg",
                    "eventSocialMediaAccounts",
                    "eventDates",
                    "promotionalActive",
                    "firstPostId",
                ]
            });

            if (!!response?.data) {
                const {
                    eventIsActive: isActive,
                    eventName: name,
                    eventSocialMediaAccounts: socialMediaAccounts,
                    eventColor: color,
                    eventCoverImg,
                    eventDates,
                    promotionalActive,
                    firstPostId: initialPostId,
                } = response.data;

                setEventIsActive(!!isActive);
                setEventName(name);
                setEventColor(color);
                setCoverImages([eventCoverImg]);
                setSocialMedia(socialMediaAccounts);
                setEventId(id);
                setEventDates(eventDates);
                setShareEmailActive(promotionalActive);
                setFirstPostId(initialPostId || "");

                getEventPosts(id, null, null).then();
            } else console.error("No response data returned from function call");
        } catch (e) {
            console.error(e);
        }
    }

    const processEventPostResponse = (response, dataPollId, batchLastItem) => {
        const fetchedPosts = response.data.eventPosts;
        const processedPollId = response.data.pollId || dataPollId;
        const processedBatchEndDetails = response.data.batchEndDetails || batchEndDetails;
        const updatedEventPostMap = new Map(!batchLastItem || batchLastItem.isRefresh ? [] : eventPostMap);
        const updatedEventImages = [];

        const sortedFetchedPosts = fetchedPosts.sort(Utility.buildSortMethod(false, "postDate", 1));

        if (fetchedPosts.length >= batchSize) {
            const [socialMediaPostIndex, shareEmailPostIndex] = postIndexes;
            sortedFetchedPosts.splice(socialMediaPostIndex, 0, {
                id: Utility.makeId(5),
                socialMediaLinks: true,
                mediaUrls: []
            });
            sortedFetchedPosts.splice(shareEmailPostIndex, 0, {id: Utility.makeId(5), shareEmail: true, mediaUrls: []});
        }

        sortedFetchedPosts.forEach((eventPost) => {
            updatedEventImages.push(...eventPost.mediaUrls);
            updatedEventPostMap.set(eventPost.id, eventPost);
        });
        if (!!updatedEventImages.length) {
            setCoverImages(updatedEventImages);
        }

        if (postLoading) {
            updatedEventPostMap.set("loading", "loading");
        }

        setEventPostMap(updatedEventPostMap);
        setPollId(processedPollId);
        setBatchEndDetails(processedBatchEndDetails);
        setLastUpdateDts(new Date());
        return processedBatchEndDetails;
    }

    const getEventPosts = async (id, dataPollId, batchLastItem) => {
        if (!getUserIsActive()) {
            console.log("User inactive");
        } else {
            try {
                const response = await Utility.httpCall("getEventPosts", {
                    eventId: id,
                    batchSize,
                    batchLastItem,  //if null only get the top batch-size posts
                    pollId: dataPollId,
                });

                if (!!response.data) {
                    if (response.data.noNewEvents) {
                        console.log("No new events found.");
                    } else {
                        return processEventPostResponse(response, dataPollId, batchLastItem);
                    }
                } else console.error("No response data returned from function call");
            } catch (e) {
                console.error(e);
            }
        }

        return batchLastItem;
    }

    const getNextPostBatch = async () => {
        if (!batchEndDetails) {
            return
        }
        console.log("getting next batch of posts")
        return await getEventPosts(eventId, "force-get-posts", batchEndDetails);
    }

    const getEventPostUpdates = async () => {
        if (!getUserIsActive()) {
            console.log("User inactive");
            return;
        }

        try {
            const response = await Utility.httpCall("getEventPostUpdates", {
                eventId,
                pollId,
                batchLastItem: batchEndDetails,
                lastUpdateDts,
            });

            if (!!response.data) {
                if (response.data.noUpdates) {
                    console.log("No new event post updates.");
                } else {
                    const eventPostUpdates = response.data.eventPostUpdates;
                    if (!!eventPostUpdates?.length) {
                        const updatedEventPostMap = new Map(eventPostMap);
                        eventPostUpdates.forEach((eventPostUpdate) => {
                            const postId = eventPostUpdate.id;
                            const postToUpdateIsCurrentlyInView = updatedEventPostMap.has(postId)
                            if (postToUpdateIsCurrentlyInView) {
                                updatedEventPostMap.set(postId, eventPostUpdate);
                            }
                        });
                        setEventPostMap(updatedEventPostMap);
                        setLastUpdateDts(new Date());
                    } else console.log("No new event post updates.");
                }
            } else console.error("No response data returned from function call");
        } catch (e) {
            console.error(e);
        }
    }

    const saveUserEmail = async (email) => {
        try {
            const response = await Utility.httpCall("saveEventsUserEmail", {
                userEmail: email,
                userId,
            });

            if (!!response?.data) {
                const userIdFromDb = response.data.id;
                setUserId(userIdFromDb || userId);
                localStorage.setItem("scale-social-events-id", userIdFromDb);
            } else console.error("No response data returned from function call");
        } catch (e) {
            console.error(e);
        }
    }

    const updateEventPost = async (postId, propToUpdate, updatedValue) => {
        const updatedPost = eventPostMap.get(postId);
        const oldPostState = {...updatedPost};

        updatedPost[propToUpdate] = updatedValue;
        const updatedEventPostMap = new Map(eventPostMap);
        updatedEventPostMap.set(postId, updatedPost);
        setEventPostMap(updatedEventPostMap);

        let success = false;
        try {
            const response = await Utility.httpCall("updateEventPost", {
                eventId,
                postId,
                propToUpdate,
                updatedValue,
            });

            success = !!response?.data;
        } catch (e) {
            console.error(e);
            success = false;
        }

        if (!success) {
            const revertedPostMap = new Map(eventPostMap);
            revertedPostMap.set(postId, oldPostState);
            setEventPostMap(revertedPostMap);
        }
    }

    const addLike = async (postId) => {
        const postToUpdate = eventPostMap.get(postId);
        const postLikes = !!postToUpdate.likes?.length ? postToUpdate.likes : [];
        const userAlreadyLikedPostIndex = postLikes.findIndex((likeId) => likeId === userId);

        if (userAlreadyLikedPostIndex < 0) {
            postLikes.push(userId);
        } else postLikes.splice(userAlreadyLikedPostIndex, 1);

        await updateEventPost(postId, "likes", postLikes);
    }

    return (
        <GeneralContext.Provider value={{
            contentLoading,
            uploadMediaModalOpen,
            appLang,
            eventId,
            currentUploads,
            hasAcceptedTerms,
            userEmail,
            userName,
            userComments,
            eventIsActive,
            socialMedia,
            eventName,
            eventColor,
            coverImages,
            eventPostMap,
            errorMsg,
            batchEndDetails,
            pollId,
            postLoading,
            shareEmailActive,
            firstPostId,

            setContentLoading,
            setUploadMediaModalOpen,
            setAppLang,
            setCurrentUploads,
            setHasAcceptedTerms,
            setUserEmail,
            setUserName,
            setUserComments,
            setBlockFetchPosts,
            setErrorMsg,

            switchLanguage,
            uploadMedia,
            uploadBlob,
            submitContent,
            getEventData,
            saveUserEmail,
            clearTempMedia,
            getNextPostBatch,
            getEventPosts,
            addLike,
        }}>
            {props.children}
        </GeneralContext.Provider>
    )
}