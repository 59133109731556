import React from "react";
import LoaderModal from "./LoaderModal";
import UploadMediaModal from "./UploadMediaModal";

const ModalContainer = () => {
    return (
        <>
            <LoaderModal/>
            <UploadMediaModal/>
        </>
    )
}

export default ModalContainer;