import React, {useContext, useState} from "react";
import "../componentStyle.scss";
import {GeneralContext} from "../../contexts/GeneralContext";
import {Utility} from "../../shared-components/Utility";
import {language} from "../../shared-components/content/language";
import StyledInput from "../../shared-components/generic/StyledInput";

const ShareEmailPost = () => {
    const {
        appLang,
        userEmail,
        setUserEmail,
        setBlockFetchPosts,
        saveUserEmail,
    } = useContext(GeneralContext);
    const content = language[appLang];
    const [timer, setTimer] = useState(null);
    const [hasSubmittedInput, setHasSubmittedInput] = useState(false);
    const [emailValid, setEmailValid] = useState(true);

    const handleEmailInputChange = (e) => {
        const updatedEmail = e.target.value;
        setUserEmail(updatedEmail);
        if (!updatedEmail) {
            setHasSubmittedInput(false);
        }
        setEmailValid(Utility.validateEmail(updatedEmail));

        if (timer) clearTimeout(timer);

        if (e.key !== "Enter") {
            const newTimer = setTimeout(() => {
                setBlockFetchPosts(false);
                setHasSubmittedInput(true);
                if (Utility.validateEmail(updatedEmail)) {
                    saveUserEmail(updatedEmail);
                }
            }, 5000);
            setTimer(newTimer);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            if (timer) clearTimeout(timer);

            setBlockFetchPosts(false);
            setHasSubmittedInput(true);

            if (Utility.validateEmail(userEmail)) {
                saveUserEmail(userEmail);
            }
        } else setBlockFetchPosts(true);
    };

    return (
        <div className="event-post additional-text">
            <div className="post-header">
                <div>{Utility.capitalize(content.PLEASE_PROVIDE_YOUR_EMAIL_SENTENCE)}</div>
            </div>
            <StyledInput
                placeholder={Utility.capitalize(content.YOUR_EMAIL_OPTIONAL)}
                value={userEmail}
                onChange={handleEmailInputChange}
                onKeyDown={handleKeyDown}
                hasError={!!hasSubmittedInput && (!!userEmail && !emailValid)}
                errorMessage={Utility.capitalize(content.VALID_EMAIL_ADDRESS_REQUIRED)}
            />
        </div>
    );
};

export default ShareEmailPost;