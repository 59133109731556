import React, {useContext, useEffect, useState} from "react";
import "./componentStyle.scss";
import {GeneralContext} from "../contexts/GeneralContext";
import {Utility} from "../shared-components/Utility";

const CoverImageViewer = () => {
    const {
        coverImages,
        eventColor
    } = useContext(GeneralContext);

    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentIndex((prevIndex) => ((prevIndex || 0) + 1) % coverImages.length);
        }, 2000);

        return () => clearInterval(intervalId);
    }, [coverImages]);

    return Utility.renderMedia(
        coverImages?.[currentIndex]?.thumbnailUrl || coverImages?.[currentIndex]?.url || coverImages?.[currentIndex],
        "Slideshow media",
        null,
        "event-cover-container",
        eventColor,
    );
};

export default CoverImageViewer;