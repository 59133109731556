import React, {useContext} from "react";
import "../componentStyle.scss";
import {GeneralContext, routes} from "../../contexts/GeneralContext";
import {language} from "../../shared-components/content/language";
import AppHeader from "../AppHeader";
import EventPost from "./EventPost";
import SocialMediaLinksPost from "./SocialMediaLinksPost";
import {Utility} from "../../shared-components/Utility";
import StyledButton from "../../shared-components/generic/StyledButton";
import {Plus} from "@phosphor-icons/react";
import {useNavigate} from "react-router-dom";
import ShareEmailPost from "./ShareEmailPost";
import LoadingCard from "./LoadingCard";
import {useMediaQuery} from "react-responsive";
import {isMobile} from "react-device-detect";
import DigitalWallFooter from "./DigitalWallFooter";
import EventPostList from "./EventPostList";

const ViewPage = () => {
    const {
        appLang,
        eventId,
        eventColor,
        socialMedia,
        eventPostMap,
        shareEmailActive,
    } = useContext(GeneralContext);
    const content = language[appLang];
    const navigate = useNavigate();
    const isLandscape = useMediaQuery({query: "(orientation: landscape)"});
    const useDigitalWallView = !isMobile && isLandscape;

    const renderPosts = () => {
        let addLoadingCard = false;
        const posts = [...eventPostMap.values()]
            .map((eventPost) => {
                if (eventPost === "loading") {
                    addLoadingCard = true;
                } else if (eventPost?.socialMediaLinks && !useDigitalWallView) {
                    return <SocialMediaLinksPost
                        label={`${Utility.capitalize(content.FOLLOW_US_ON)}:`}
                        socialMedia={socialMedia}
                        key={eventPost.id}
                    />
                } else if (shareEmailActive && eventPost?.shareEmail && !useDigitalWallView) {
                    return <ShareEmailPost key={eventPost.id}/>
                } else if (!eventPost?.shareEmail && !eventPost?.socialMediaLinks) {
                    return <EventPost eventPost={eventPost} key={eventPost.id} eventColor={eventColor}/>
                }
            });

        if (addLoadingCard) {
            posts.unshift(<LoadingCard message={`${Utility.capitalize(content.LOADING)}...`} key={"loading-new-post"}/>)
        }

        return <EventPostList posts={posts} useDigitalWallView={useDigitalWallView}/>;
    }

    return (
        <div className={`main-container view-page${!useDigitalWallView ? " with-header" : ""}`}>
            {useDigitalWallView
                ? null
                : <AppHeader withSearch={false} isViewPage={true}/>
            }
            {renderPosts()}
            {useDigitalWallView
                ? <DigitalWallFooter/>
                : <StyledButton
                    className="floating-share-btn additional-text"
                    type="main"
                    brandColorOverride={eventColor}
                    onClick={() => navigate(routes.UPLOAD + "/?id=" + eventId)}
                >
                    <Plus color="#FFFFFF" weight="bold" size={14}/> {Utility.capitalize(content.SHARE)}
                </StyledButton>
            }
        </div>
    );
};

export default ViewPage;